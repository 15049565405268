<template>
  <en-drawer title="返工记录" :model-value="modelValue" @close="$emit('update:model-value', false)">
    <en-form>
      <en-form-item label="项目名称">{{ form.data?.name }}</en-form-item>
      <en-form-item label="施工班组">{{ form.data?.workingTeam?.name }}</en-form-item>
      <en-form-item label="施工人员">
        {{ form.data?.assignees?.map((item) => (item.assignee ? item.assignee.name : item.name)).join(',') }}
      </en-form-item>
    </en-form>

    <en-table :data="form.data?.reworks">
      <en-table-column label="检验时间">
        <template #default="{ row }: { row: Definitions['ServiceMaintenanceInternalReworkDto'] }">
          {{ formatDate(row.finalInspectorDatetime) }}
        </template>
      </en-table-column>
      <en-table-column label="终检人" prop="finalInspector.name"></en-table-column>
      <en-table-column label="返工原因" prop="reason"></en-table-column>
      <en-table-column label="操作">
        <template #default="{ $index }: { $index: number }">
          <en-button type="primary" text @click="table.discard.click($index)">作废</en-button>
        </template>
      </en-table-column>
    </en-table>

    <template #footer>
      <en-button @click="$emit('update:model-value', false)">取消</en-button>
      <en-button @click="$emit('confirm', form.data), $emit('update:model-value', false)">确定</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash-es'

export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudServiceDefinitions['ServiceMaintenanceDto'] | null>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (value: EnocloudServiceDefinitions['ServiceMaintenanceDto'] | null) => typeof value === 'object'
  },

  watch: {
    modelValue: {
      handler(visible) {
        if (visible) this.form.data = cloneDeep(this.data) ?? null
      }
    }
  },

  config: {
    children: {
      form: {
        data: null as EnocloudServiceDefinitions['ServiceMaintenanceDto'] | null
      },
      table: {
        children: {
          discard: {
            click(index: number) {
              this.form.data?.reworks.splice(index, 1)
            }
          }
        }
      }
    }
  }
})
</script>
